div[id$="before"] {
}

h1.global-title {
    margin: 2vh 0;
    font-size: 2rem;
    position: relative;
    left: 5vw;
    bottom: 2vh;
    display: inline;
}

div:has(.global-title) {
    display: inline;
}

