.experience-card,
.experience-card * {
    display: inline-block;
}

.text-with-borders::after {
    content: "//";
    color: #c9cccf;
    margin-right: 10px;
}

.text-with-borders {
    margin-left: 40px;
}


.experience-card h1 {
    font-size: 1.7rem;
    color: rgba(255, 255, 255, .7);
}
