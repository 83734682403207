@font-face{
  font-family: 'JetBrains Mono';
  src: url('fonts/jetbrains/JetBrainsMono-Regular.woff2') format('woff2'),
  url('fonts/jetbrains/JetBrainsMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
:root {
  --background-color: #07051f;
  --black-white: rgba(255, 255, 255, .6)
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "JetBrains Mono", sans-serif;
  color: white;
  padding: 0;
  margin: 0;
}

html {
  font-size: 20px;
  overflow-x: hidden;
  background: var(--background-color);
}

section {
  width: 100vw;
  display: inline-block;
}


section:not(:first-child) > div:last-child {
  width: 80%;
}

