.full {
    position: fixed;
    background: var(--background-color);
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 110vh;
}

.close {
    position: absolute;
    top: 5%;
    right: 12%;
    font-size: 35px;
}

.center-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-content > ul > li {
    padding: .8vh 0;
    font-size: 30px;
}
