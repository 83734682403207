.printing > * {
    display: inline-block;
}

.printing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.printing > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cursor {
    width: 5px;
    height: 2rem;
    background: white;
    margin-left: 10px;
}

.blinking {
    animation: cursor-blink 1s forwards infinite;
}

@keyframes cursor-blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
