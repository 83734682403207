#projects {
    width: 100%;
    display: flex;
    min-height: 70vh;
}

#projects > .content {
    width: 90%;
    margin-left: 10vw;
}

.type-element {
    display: inline-block;
    color: var(--black-white);
    margin-right: 4%;
    transition: all 200ms;
    font-size: 1.4rem;
}

.type-element:hover {
    color: white;
}

.projects-grid {
    margin-top: 2%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
}

.projects-grid > .projects-name {
    flex: 2 1;
}

.projects-grid .info {
    flex: 1.2 1;
}

.content {
    display: inline-flex;
    flex-direction: column;
}

.content > ul {
    flex: 1 1;
}

.content > .projects-grid {
    flex: 20 1;
}
