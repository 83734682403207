.experience {
    width: 80%;
    display: flex;
    margin-bottom: 10%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.experience > div {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1;
}

.experience > div > *:nth-child(2) {
    margin-top: 20px;
}

.experience .experience-card  {
    position: relative;
    left: 10vw;
    bottom: 2vh;
    padding: 10px 0 0  ;
}

