.info {
    overflow: hidden;
    display: inline-block;
    height: 100%;
}

.info > .projects-image {
    width: 100%;
    height: 30vh;
    margin-bottom: 2vh;
    /*
    animation: animate-image 400s linear infinite;
    */
}

.info p {
    color: var(--black-white);
}

.printing > p.title {
    color: white;
    font-size: 2rem;
}

.info > .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.content > .description {
    flex: 7;
}

.content > .stack {
    flex: 2;
    height: 100%;
    font-size: .8rem;
}

@keyframes animate-image {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

