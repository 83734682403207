
#portfolio {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

#portfolio > h1 {
    flex: 1 1;
}

#portfolio > #projects {
    flex: 20 1;
}

