.contact {
    display: inline-block;
}

.contact > .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.write {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    left: 10vw;
    flex: 1;
}

.contacts {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contacts > div {
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.write > form {
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.write > form > span {
    align-self: center;
}

.center {
    display: flex;
}

.contact > .content {
    padding: 0 !important;
    margin: 0;
}

.write, .contact {
    height: 50%;
}

.content > .write > form {
    width: 55%;
    min-width: 300px;
    padding-bottom: 10%;
}

.contacts {
    justify-content: center;
    align-items: center;
}


.contact-title {
    color: var(--black-white)
}

.contacts p {
    font-size: .9rem;
}

.contacts h1 {
    font-size: 1rem;
    padding-bottom: 2vh;
}

input[type="text"], textarea {
    background: var(--background-color);
    border: 2px white solid;
    padding: 10px;
    font-size: 0.9rem;
}

textarea {
    resize: none;
    height: 120px;
}

#message {
    min-height: 30%;
}

#send {
    color: white;
    background: var(--background-color);
    height: 40px;
    border: 2px white solid;
    font-size: 0.9rem;
}
